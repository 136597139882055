//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import $ from 'jquery/dist/jquery.min.js'
import { validationMixin } from 'vuelidate'
import { required, email, maxLength } from 'vuelidate/lib/validators'
// import { getSign } from '@/plugins/utils'

export default {
  mixins: [validationMixin],
  props: {
    backgroundColor: {
      type: String,
      default: '#8af4d1'
    }
  },
  data () {
    return {
      errorMessage: '',
      submitted: false,
      sendingData: false,
      policy: false,
      form: {
        email: ''
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
        maxLength: maxLength(255)
      }
    }
  },
  computed: {
    getBgColor () {
      if (typeof this.backgroundColor === 'string' && this.backgroundColor !== '') {
        return `background-color: ${this.backgroundColor};`
      }
      return 'background-color: #8af4d1;'
    }
  },
  destroyed () {
    this.errorMesage = ''
    this.submitted = false
    this.sendingData = false
    this.policy = false
    this.form.email = ''
  },
  methods: {
    validateState (name) {
      // console.log(name, this.$v.form[name])
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit () {
      // this.errorMessage = ''
      // this.$v.form.$touch()
      // if (this.$v.form.$anyError) {
      //   return
      // }
      // // console.log('submit', this.form)
      // const data = {
      //   email: this.form.email
      // }
      // const sign = getSign(data)
      // // console.log('data', { ...data, ...{ sign } })
      // this.sendingData = true
      // $.ajax({
      //   method: 'post',
      //   url: 'https://api.pagsmile.com/index.php?r=api%2Fregemial',
      //   data: { ...data, ...{ sign } }
      // })
      //   .then((res) => {
      //     this.sendingData = false
      //     const response = JSON.parse(res)
      //     if (Number(response.code) === 200) {
      //       this.submitted = true
      //       /* baidu analytics submit start */
      //       window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      //       /* baidu analytics submit end */
      //       return true
      //     } else {
      //       this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //     }
      //   }).catch(() => {
      //     this.sendingData = false
      //     this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //   })
    }
  }
}
